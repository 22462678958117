<template>
	<Experience
		:experiences="experiences"
		:has-icons="hasIcons"
		:texts="texts"
		:isInternal="isInternal"
		:mapClass="mapClass"
		:nbPerSlide="nbPerSlide"
		:rtl="rtl"
		:isLandscape="isLandscape"
		:class="{ 'generation-ready': loaded, 'experience-container': true }"
	/>
</template>

<script>
import Experience from '@/components/Experience';
export default {
	name: 'Generate',
	components: {
		Experience
	},
	data () {
		return {
			experiences: [],
			mapClass: '',
			hasIcons: false,
			texts: {},
			loaded: false,
			isInternal: false,
			nbPerSlide: 6,
			rtl: false,
			isLandscape: false
		};
	},
	async created () {
		const hash = this.$route.query.hash;
		if (hash) {
			const config = (await this.axios.get(`/api/v1/generate/${hash}`)).data;
			this.experiences = config.experiences || [];
			this.hasIcons = config.hasIcons;
			this.texts = config.texts || {};
			this.isInternal = config.isInternal;
			this.nbPerSlide = config.nbPerSlide || 6;
			this.mapClass = config.mapClass || '';
			this.rtl = config.rtl;
			this.isLandscape = config.isLandscape;
			this.slidesOrder = config.slidesOrder;
			if (this.isLandscape) {
				document.querySelector('html').classList.add('_landscape');
			}
		}
		this.loaded = true;
	}
};
</script>

<style lang="scss">

.experience-container {
	width: 100%;
	height: 100%;
}

html {
	-webkit-print-color-adjust: exact;
	font-size: 32px;

	.slides {
		display: block !important;
		> * {
			height: 11in;
		}
	}
}


//
// landscape mode
//

html._landscape {
	font-size: 6.79px;

	.slides {
		> * {
			width: 8.5in;
			height: 4.78in;
		}
	}
}

</style>
